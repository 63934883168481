import React from 'react';
import PropTypes from 'prop-types';

const VeryGoodIcon = ({ color }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 61.6 61.6"
    style={{ enableBackground: 'new 0 0 61.6 61.6', height: '50px' }}
    xmlSpace="preserve"
  >
    <style type="text/css">
      {`
    .st0{fill:#F5F5F5;}
    .st1{fill:#E0E0E0;}
    .st2{fill:#EBEBEB;}'
    .st3{display:none;}
    .st4{display:inline;}
    .st5{fill:#263238;}
    .st6{fill:#FFFFFF;}
    .st7{fill:#455A64;}
    .st8{opacity:0.4;}
    .st9{fill:#FF725E;}
    .st10{opacity:0.3;}
    .st11{fill:#FFBF9D;}
    .st12{fill:#FF9A6C;}
    .st13{opacity:0.6;}
    `}
    </style>
    <g id="Background_Complete" />
    <g id="Background_Simple" className="st3" />
    <g id="Floor" />
    <g id="Device" />
    <g id="Plant" />
    <g id="Shadow_2" />
    <g id="Shadow_1" />
    <g id="Emotions">
      <g>
        <g>
          <g>
            <ellipse
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -12.7646 30.8164)"
              className="st1"
              style={{
                fill: color,
                opacity: '0.6',
              }}
              cx="30.8"
              cy="30.8"
              rx="30.8"
              ry="30.8"
            />
          </g>
          <g>
            <path
              className="st5"
              d="M40.7,25.6c0,2.2,1.4,4.1,3.1,4.1c1.7,0,3.1-1.8,3.1-4.1s-1.4-4.1-3.1-4.1C42.1,21.5,40.7,23.3,40.7,25.6z"
            />
          </g>
          <g>
            <path
              className="st5"
              d="M15.1,25.6c0,2.2,1.4,4.1,3.1,4.1c1.7,0,3.1-1.8,3.1-4.1s-1.4-4.1-3.1-4.1C16.5,21.5,15.1,23.3,15.1,25.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path className="st5" d="M20.2,31.6c0,6.2,4.8,11.3,10.6,11.3c5.9,0,10.6-5.1,10.6-11.3H20.2z" />
          </g>
          <g>
            <path className="st9" d="M25.3,41.2c0,0,2.1-2.7,5.5-2.7c3.4,0,5.5,2.7,5.5,2.7S31.2,44.6,25.3,41.2z" />
          </g>
        </g>
      </g>
    </g>
    <g id="Speech_Bubble_2" />
    <g id="Speech_Bubbles_1" />
    <g id="Character_00000028301238202153125090000011725317768445582000_" />
  </svg>
);

export default VeryGoodIcon;

VeryGoodIcon.propTypes = {
  color: PropTypes.string,
};
