import { configureStore } from '@reduxjs/toolkit';
import EnquiryReducer from './enquiry';
import SlotReducer from './slot';
import CommonReducer from './common';
import feedbackReducer from './feedback';
import locationReducer from './location';

export default configureStore({
  reducer: {
    enquirys: EnquiryReducer,
    slot: SlotReducer,
    common: CommonReducer,
    feedback: feedbackReducer,
    location: locationReducer,
  },
});
