export const colors = {
  // primary: '#EF4923',
  primary: '#ec2927',
  secondary: '#FFEFEC',
  white: '#ffffff',
  black: '#000000',
  light: '#E4AE92',
  exLight: '#FEF5CE',
  green: '#418e8c',
  grey: ' #959595',
};
