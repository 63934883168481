import React from 'react';
import propTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';
import Slider from 'react-slick'; // Import the Slider component from react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// Import icons from Material-UI Icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useDevice } from '../../../hooks/useResponsive';
import { colors } from '../../../theme/colors';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

Banner.propTypes = {
  addMargin: propTypes.any,
};
export default function Banner({ addMargin }) {
  const { isMobile } = useDevice();

  // Custom left arrow component
  const CustomPrevArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-left LeftArrow"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ChevronLeftIcon /> {/* Use the ChevronLeftIcon from Material-UI Icons */}
      </div>
    );
  };

  // Custom right arrow component
  const CustomNextArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-right RighrArrow"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ChevronRightIcon /> {/* Use the ChevronRightIcon from Material-UI Icons */}
      </div>
    );
  };

  // Configuration for the carousel
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />, // Custom left arrow component
    nextArrow: <CustomNextArrow />, // Custom right arrow component
  };

  return (
    <div style={{ marginBottom: addMargin ? '2rem' : 0 }}>
      <StyledRoot sx={{ backgroundColor: colors.secondary, paddingTop: '20px', paddingBottom: '20px  ' }}>
        <Container maxWidth="lg" sx={{ paddingTop: '25px', paddingBottom: '25px' }}>
          <Grid>
            <Typography className="Transformmain-typo boldfont" component="div" gutterBottom>
              How We Transform Your Space!
            </Typography>
          </Grid>
          {isMobile ? (
            <Slider {...carouselSettings}>
              {/* Carousel Slides */}
              <div>
                {/* Slide 1 */}
                <Card className="CardTransform">
                  <CardMedia
                    component="img"
                    className="card-img samesize"
                    src={`/assets/images/Home/calculator.png`}
                    alt="Card Image"
                  />
                  <CardContent className="Card-content">
                    <Typography
                      className="boldfont dnone"
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'black' }}
                    >
                      Step 1
                    </Typography>
                    <Typography className="card-content boldfont" component="div" gutterBottom>
                      Estimate & Schedule FREE Home Visit
                    </Typography>
                  </CardContent>
                </Card>
              </div>
              <div>
                {/* Slide 2 */}
                <Card className="CardTransform">
                  <Card className="CardTransform">
                    <CardMedia
                      component="img"
                      className="card-img samesize"
                      src={`/assets/images/Home/Mask group.png`}
                      alt="Card Image"
                    />
                    <CardContent className="Card-content">
                      <Typography
                        className="boldfont dnone"
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'black' }}
                      >
                        Step 2
                      </Typography>
                      <Typography className="card-content boldfont" component="div" gutterBottom>
                        Home Visit of ŹExperts
                      </Typography>
                    </CardContent>
                  </Card>
                </Card>
              </div>

              <div>
                {/* Slide 3 */}
                <Card className="CardTransform">
                  <Card className="CardTransform">
                    <CardMedia
                      component="img"
                      className="card-img samesize"
                      src={`/assets/images/Home/3.jpg`}
                      alt="Card Image"
                    />
                    <CardContent className="Card-content">
                      <Typography
                        className="boldfont dnone"
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'black' }}
                      >
                        Step 3
                      </Typography>
                      <Typography className="card-content boldfont" component="div" gutterBottom>
                        Approve the Quote
                      </Typography>
                    </CardContent>
                  </Card>
                </Card>
              </div>
              <div>
                {/* Slide 4 */}
                <Card className="CardTransform">
                  <Card className="CardTransform">
                    <CardMedia
                      component="img"
                      className="card-img samesize"
                      src={`/assets/images/Home/Maskhome.png`}
                      alt="Card Image"
                    />
                    <CardContent className="Card-content">
                      <Typography
                        className="boldfont dnone"
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'black' }}
                      >
                        Step 4
                      </Typography>
                      <Typography className="card-content boldfont" component="div" gutterBottom>
                        Install Curtains in 5 Days
                      </Typography>
                    </CardContent>
                  </Card>
                </Card>
              </div>
            </Slider>
          ) : (
            <Grid container spacing={2}>
              {/* First Row */}
              <Grid item xs={12} sm={6} md={3}>
                <Card className="CardTransform">
                  <CardMedia
                    component="img"
                    className="card-img samesize"
                    src={`/assets/images/Home/calculator.png`}
                    alt="Card Image"
                  />
                  <CardContent className="Card-content">
                    <Typography
                      className="boldfont dnone"
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'black' }}
                    >
                      Step 1
                    </Typography>
                    <Typography className="card-content boldfont" component="div" gutterBottom>
                      Estimate & Schedule FREE Home Visit
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card className="CardTransform">
                  <CardMedia
                    component="img"
                    className="card-img samesize"
                    src={`/assets/images/Home/Mask group.png`}
                    alt="Card Image"
                  />
                  <CardContent className="Card-content">
                    <Typography
                      className="boldfont dnone"
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'black' }}
                    >
                      Step 2
                    </Typography>
                    <Typography className="card-content boldfont" component="div" gutterBottom>
                      Home Visit of ŹExperts
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <Card className="CardTransform">
                  <CardMedia
                    component="img"
                    className="card-img samesize"
                    src={`/assets/images/Home/3.jpg`}
                    alt="Card Image"
                  />
                  <CardContent className="Card-content">
                    <Typography
                      className="boldfont dnone"
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'black' }}
                    >
                      Step 3
                    </Typography>
                    <Typography className="card-content boldfont" component="div" gutterBottom>
                      Approve the Quote
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <Card className="CardTransform">
                  <CardMedia
                    component="img"
                    className="card-img samesize"
                    src={`/assets/images/Home/Maskhome.png`}
                    alt="Card Image"
                  />
                  <CardContent className="Card-content">
                    <Typography
                      className="boldfont dnone"
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'black' }}
                    >
                      Step 4
                    </Typography>
                    <Typography className="card-content boldfont" component="div" gutterBottom>
                      Install Curtains in 5 Days
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Container>
      </StyledRoot>
    </div>
  );
}
