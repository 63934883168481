import React from 'react';
import { CardMedia, Container, Typography, Card, CardContent, Button, Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import Feature from '../segment/@common/Feature/feature';
import { colors } from '../theme/colors';
import { useDevice } from '../hooks/useResponsive';
import curtainTypesData from '../mock/curtains';
import EstimateCard from '../segment/Products/Estimate';
import Zealbanner from '../segment/@common/Zealbanner/zealbanner';

export default function ProductPage() {
  const { isMobile } = useDevice();

  return (
    <>
      {isMobile ? (
        <Box>
          <img src={'/assets/images/Product/banner2.png'} alt="" />
        </Box>
      ) : (
        <Box>
          <img src={'/assets/images/Product/banner.png'} alt="" />
        </Box>
      )}
      <Box sx={{ paddingTop: isMobile ? 0 : '20px', paddingBottom: isMobile ? 0 : '20px' }}>
        <Container
          maxWidth="lg"
          sx={{ paddingTop: isMobile ? 0 : '25px', paddingBottom: '25px', px: isMobile ? 0 : '' }}
        >
          <Stack
            mt={isMobile && -10}
            flexWrap={'wrap'}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            {curtainTypesData?.map((curtain, index) => (
              <Card key={index} sx={{ width: '470px', mx: 4, my: 3, borderRadius: 0 }}>
                <CardMedia component="img" image={curtain?.bannerImage} alt={curtain.alt} />
                <CardContent sx={{ textAlign: 'center', p: 4 }}>
                  <Box>
                    <Typography
                      className="boldfont"
                      gutterBottom
                      sx={{
                        color: colors.primary,
                        textTransform: 'uppercase',
                        fontSize: isMobile && '14px',
                      }}
                      variant="h6"
                      noWrap
                    >
                      {curtain?.title}
                    </Typography>
                    <Typography
                      variant={isMobile ? 'caption' : 'body2'}
                      gutterBottom
                      component={'p'}
                      sx={{ px: isMobile ? 0 : 3, color: 'black' }}
                    >
                      {curtain.description}
                    </Typography>
                  </Box>
                  <EstimateCard price={curtain.price} />
                </CardContent>
                <Button
                  component={Link}
                  to="/booking"
                  className="zeal-btn-solid"
                  sx={{
                    borderRadius: 0,
                    fontSize: isMobile ? '15px' : '20px',
                    width: '100%',
                    height: '60px',
                    fontWeight: 600,
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => localStorage.setItem('category', curtain?.category)}
                >
                  <Box mr={3}>
                    <img src="/assets/images/Header/icon.png" alt="icons" />
                  </Box>{' '}
                  Book A Free Home Visit
                </Button>
              </Card>
            ))}
          </Stack>
        </Container>
      </Box>
      <Zealbanner />
      <Feature />
    </>
  );
}
