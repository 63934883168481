import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useDevice } from '../hooks/useResponsive';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',

  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page404({ isInner }) {
  const styledContainer = { textAlign: 'center', alignItems: 'center' };
  const { isMobile } = useDevice();
  return (
    <>
      {!isInner && (
        <Helmet>
          <title> 404 Page Not Found | ZealCurtains </title>
        </Helmet>
      )}

      <Container>
        <StyledContent sx={isInner ? { ...styledContainer, minHeight: isMobile ? '50vh' : '100vh' } : styledContainer}>
          <Typography variant="h3" paragraph sx={{ color: 'rgba(236,41,39,255)' }} className="boldfont">
            Sorry, page not found!
          </Typography>

          <Typography sx={{ color: '' }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your
            spelling.
          </Typography>

          <Box
            component="img"
            src="/assets/illustrations/jaconda.gif"
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />

          {!isInner && (
            <Button to="/" size="large" className="btn404" variant="contained" component={RouterLink}>
              Go to Home
            </Button>
          )}
        </StyledContent>
      </Container>
    </>
  );
}

Page404.propTypes = {
  isInner: PropTypes.bool,
};
