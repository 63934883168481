import React from 'react';
import PropTypes from 'prop-types';

import { Grid, IconButton } from '@mui/material';
import AverageIcon from '../feedBackIcons/averageIcon';
import GoodIcon from '../feedBackIcons/goodIcon';
import PoorIcon from '../feedBackIcons/poorIcon';
import VeryGoodIcon from '../feedBackIcons/veryGoodIcon';
import VeryPoorIcon from '../feedBackIcons/veryPoorIcon';

function EmojiSelector({ select, setSelect }) {
  return (
    <Grid
      container
      spacing={4}
      pt={'18px'}
      sx={{
        display: 'flex',
        flexDirection: { sx: 'row' },
      }}
    >
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <IconButton sx={{ padding: 0 }} onClick={() => setSelect(1)}>
          <VeryPoorIcon color={select === 1 ? '#FA0202' : 'lightgray'} />
        </IconButton>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <IconButton sx={{ padding: 0 }} onClick={() => setSelect(2)}>
          <PoorIcon color={select === 2 ? '#ba2c04' : 'lightgray'} />
        </IconButton>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <IconButton sx={{ padding: 0 }} onClick={() => setSelect(3)}>
          <AverageIcon color={select === 3 ? '#805207' : 'lightgray'} />
        </IconButton>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <IconButton sx={{ padding: 0 }} onClick={() => setSelect(4)}>
          <GoodIcon color={select === 4 ? '#3b7f09' : 'lightgray'} />
        </IconButton>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <IconButton sx={{ padding: 0 }} onClick={() => setSelect(5)}>
          <VeryGoodIcon color={select === 5 ? '#119b0b' : 'lightgray'} />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default EmojiSelector;

EmojiSelector.propTypes = {
  select: PropTypes.any,
  setSelect: PropTypes.func,
};
