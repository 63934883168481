import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography, Box, Stack } from '@mui/material';
import Feature from '../segment/@common/Feature/feature';
import { useDevice } from '../hooks/useResponsive';
import { colors } from '../theme/colors';
// import coverimg from './interior.png';

export default function AboutPage() {
  const { isMobile } = useDevice();
  const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }));

  return (
    <>
      <StyledRoot sx={{ paddingTop: isMobile ? 0 : '20px', paddingBottom: isMobile ? 0 : '20px' }}>
        <Container
          maxWidth="lg"
          sx={{ paddingTop: isMobile ? 0 : '25px', paddingBottom: '25px', px: isMobile ? 0 : '' }}
        >
          <Typography
            sx={{
              marginTop: isMobile ? '1.2rem' : '1.5rem',
              textAlign: 'center',
              marginBottom: isMobile ? '2px' : '5px',
              color: colors?.grey,
              fontSize: 24,
              fontWeight: 800,
            }}
          >
            Hi, We’re
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: isMobile ? '10px' : '20px' }}>
            <img
              src={'/assets/logo-black.png'}
              alt=""
              style={{ width: isMobile ? '272px' : '380px', height: isMobile ? '66px' : '92px' }}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                textAlign: 'center',
                paddingBottom: '30px',

                fontWeight: '400',
                fontsize: isMobile ? '16px' : '17px',
                lineHeight: 'normal',
                color: '#000',
                width: isMobile ? '313px' : '725px',
                margin: 'auto',
              }}
            >
              At Zeal, we are passionate about transforming your living spaces into extraordinary havens that reflect
              your unique style and cater to your every need. With our exquisite range of curtains, blinds, and window
              coverings, we strive to bring beauty, functionality, and innovation to every home.
            </Typography>
          </Box>
          {isMobile ? (
            <Box>
              <img src={'/assets/images/About/about2.jpg'} alt="" />
            </Box>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
              <img
                src={'/assets/images/About/about.jpg'}
                style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}
                alt=""
              />
            </Box>
          )}
        </Container>
      </StyledRoot>
      <StyledRoot sx={{ paddingTop: isMobile ? 0 : '20px', paddingBottom: isMobile ? 0 : '20px' }}>
        <Container
          maxWidth="lg"
          sx={{ paddingTop: isMobile ? 0 : '25px', paddingBottom: '25px', px: isMobile ? 0 : '' }}
        >
          <Stack flexWrap={'wrap'} direction={'row'} alignItems={'center'} justifyContent={'center'}>
            <StyledRoot>
              <Container
                maxWidth="lg"
                className="gridsun"
                sx={{ paddingTop: isMobile ? '0px' : '45px', paddingBottom: isMobile ? '0px' : '45px' }}
              >
                <Stack
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  direction={{ lg: 'row', md: 'row', xs: 'column-reverse' }}
                >
                  {/* Left Side Column - Content */}
                  <div className="left-column spleft" style={{ width: '50%', flex: 1, padding: '0 90px 0 90px' }}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        color: colors.primary,
                        fontWeight: '900',
                        fontSize: isMobile ? 30 : 36,
                        lineHeight: 'normal',
                      }}
                      className="boldfont"
                    >
                      Our Commitment <br /> to Quality
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontWeight: '400',
                        fontSize: 15,
                        lineHeight: 'normal',
                        marginTop: '10px',
                        marginBottom: '20px',
                      }}
                    >
                      We take pride in offering premium-quality products that are built to last. Each curtain and blind
                      in our collection is meticulously crafted using the finest materials and attention to detail,
                      ensuring superior durability and performance. We believe that every element of your home deserves
                      the utmost care and precision.
                    </Typography>
                  </div>

                  {/* Right Side Column - Image */}
                  <div className="right-column abtpadding" style={{ width: { xs: '100%', sm: '50%' } }}>
                    {/* Add your image here */}
                    <img
                      src={`/assets/images/About/quality.png`}
                      alt="interiour"
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  </div>
                </Stack>
              </Container>
            </StyledRoot>
            {/* creativity */}
            <StyledRoot>
              <Container
                maxWidth="lg"
                className="gridsun"
                sx={{ paddingTop: isMobile ? '0px' : '45px', paddingBottom: isMobile ? '0px' : '45px' }}
              >
                <Stack alignItems={'center'} justifyContent={'space-between'} direction={{ lg: 'row', md: 'row' }}>
                  {/* Right Side Column - Image */}
                  <div className="right-column abtpadding" style={{ width: { xs: '100%', sm: '50%' } }}>
                    {/* Add your image here */}
                    <img
                      src={`/assets/images/About/creativity.jpg`}
                      alt="interiour"
                      style={{ objectFit: 'cover', width: '100%' }}
                    />
                  </div>
                  {/* Left Side Column - Content */}
                  <div className="left-column spright" style={{ width: '100%', flex: 1, padding: '0 70px 0 90px' }}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        color: colors.primary,
                        fontWeight: '900',
                        fontSize: isMobile ? 30 : 36,
                        lineHeight: 'normal',
                      }}
                      className="boldfont"
                    >
                      Unleashing <br /> Creativity
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontWeight: '400',
                        fontSize: '15px',
                        lineHeight: 'normal',
                        marginTop: '10px',
                        marginBottom: '20px',
                      }}
                      // flexWrap
                    >
                      We understand that your home is a reflection of your personality and taste. That's why our team of
                      design experts is dedicated to helping you unleash your creativity and bring your vision to life.
                      Whether you prefer a timeless and elegant look or a bold and contemporary style, we have a wide
                      selection of designs, patterns, and fabrics to choose from, ensuring that you find the perfect
                      window coverings to complement your space.
                    </Typography>
                  </div>
                </Stack>
              </Container>
            </StyledRoot>
            {/* tailored */}
            <StyledRoot>
              <Container
                maxWidth="lg"
                className="gridsun"
                sx={{ paddingTop: isMobile ? '0px' : '45px', paddingBottom: isMobile ? '0px' : '45px' }}
              >
                <Stack
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  direction={{ lg: 'row', md: 'row', xs: 'column-reverse' }}
                >
                  {/* Left Side Column - Content */}
                  <div className="left-column spleft" style={{ width: '50%', flex: 1, padding: '0 90px 0 90px' }}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        color: colors.primary,
                        fontWeight: '900',
                        fontSize: isMobile ? 30 : 36,
                        lineHeight: 'normal',
                      }}
                      className="boldfont"
                    >
                      Tailored Solutions
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontWeight: '400',
                        fontSize: 15,
                        lineHeight: 'normal',
                        marginTop: '10px',
                        marginBottom: '20px',
                      }}
                    >
                      At Zeal, we believe in personalized solutions that cater to your specific needs. Our experienced
                      consultants work closely with you to understand your preferences, lifestyle, and functional
                      requirements. We take into account factors such as light control, privacy, energy efficiency, and
                      aesthetic appeal to create tailored window covering solutions that enhance your living
                      environment.
                    </Typography>
                  </div>

                  {/* Right Side Column - Image */}
                  <div className="right-column abtpadding" style={{ width: { xs: '100%', sm: '50%' } }}>
                    {/* Add your image here */}
                    <img
                      src={`/assets/images/About/tiolerd.png`}
                      alt="interiour"
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  </div>
                </Stack>
              </Container>
            </StyledRoot>
            {/* exceptional  */}
            <StyledRoot>
              <Container
                maxWidth="lg"
                className="gridsun"
                sx={{ paddingTop: isMobile ? '0px' : '45px', paddingBottom: isMobile ? '0px' : '45px' }}
              >
                <Stack alignItems={'center'} justifyContent={'space-between'} direction={{ lg: 'row', md: 'row' }}>
                  {/* Right Side Column - Image */}
                  <div className="right-column abtpadding" style={{ width: { xs: '100%', sm: '50%' } }}>
                    {/* Add your image here */}
                    <img
                      src={`/assets/images/About/exceptional.png`}
                      alt="interiour"
                      style={{ objectFit: 'cover', width: '100%' }}
                    />
                  </div>
                  {/* Left Side Column - Content */}
                  <div className="left-column spright" style={{ width: '100%', flex: 1, padding: '0 70px 0 90px' }}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        color: colors.primary,
                        fontWeight: '900',
                        fontSize: isMobile ? 30 : 36,
                        lineHeight: 'normal',
                      }}
                      className="boldfont"
                    >
                      Exceptional Service
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontWeight: '400',
                        fontSize: '15px',
                        lineHeight: 'normal',
                        marginTop: '10px',
                        marginBottom: '20px',
                      }}
                      // flexWrap
                    >
                      Customer satisfaction is at the heart of everything we do. From the moment you explore our
                      collection to the final installation, we strive to provide you with a seamless and enjoyable
                      experience. Our dedicated customer service team is always ready to assist you, answering your
                      queries and ensuring that you receive the highest level of support throughout your journey with
                      us.
                    </Typography>
                  </div>
                </Stack>
              </Container>
            </StyledRoot>
          </Stack>
        </Container>
      </StyledRoot>

      <Feature hideSections />
    </>
  );
}
