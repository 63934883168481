import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { getUserLocation, searchPlaces } from "store/actions"
// import { IoLocationOutline } from 'react-icons/io5';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import { getUserLocation, getSearchPlaces } from '../../../server/api/location';

export function MapSearch({ zoomTo, setMarkers }) {
  const dispatch = useDispatch();
  const [places, setPlaces] = useState();
  const [showDropDown, setShowDropDown] = useState(false);
  const { allPlaces } = useSelector((state) => ({
    allPlaces: state.location.places,
  }));
  console.log(allPlaces);

  useEffect(() => {
    if (places) {
      const data = {
        query: places,
        dispatch,
      };
      dispatch(getSearchPlaces(data));
    }
  }, [dispatch, places]);

  // const handleChangePlaces = (e) => {
  //   setPlaces(e.target.value);
  //   setShowDropDown(true);
  // };
  const handleChangePlaces = (e) => {
    const newPlaces = e.target.value;
    setPlaces(newPlaces);

    if (newPlaces === '') {
      setShowDropDown(false); // Hide dropdown if the input is empty
    } else {
      setShowDropDown(true); // Show dropdown if there's a search query
    }
  };
  const handlePlacesSelect = (item) => {
    setPlaces(item?.place);
    zoomTo({
      lat: item?.lat,
      lng: item?.lon,
    });
    setShowDropDown(false);
    dispatch(getUserLocation([item?.lat, item?.lon]));

    localStorage.setItem('pre_location', [item?.lat, item?.lon]);
    setMarkers(() => [
      {
        lat: item?.lat,
        lng: item?.lon,
        time: new Date(),
      },
    ]);
  };

  return (
    <div className="search_map">
      <input
        className="search-input"
        value={places}
        onChange={(e) => handleChangePlaces(e)}
        placeholder="search places"
      />
      {showDropDown && (
        <div className="searched_list">
          {map(allPlaces?.slice(0, 10), (item, key) => (
            // eslint-disable-next-line react/button-has-type
            <button
              className="d-flex align-items-center cursor-pointer search-btn"
              key={key}
              onClick={() => handlePlacesSelect(item)}
            >
              {/* <IoLocationOutline className="mr-5px" />  */}
              {item?.place?.slice(0, 30)}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

MapSearch.propTypes = {
  zoomTo: PropTypes.func,
  setMarkers: PropTypes.func,
};
