import React, { Fragment } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
// components
import { useDevice } from '../hooks/useResponsive';
import Banner from '../segment/@common/Banner/banner';
import Zealbanner from '../segment/@common/Zealbanner/zealbanner';
import { MetaHelmet } from '../layouts/Helmet/Helmet';
import Feature from '../segment/@common/Feature/feature';
import { colors } from '../theme/colors';
import curtainTypesData from '../mock/curtains';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export default function HomePage() {
  const { isMobile } = useDevice();
  return (
    <>
      <MetaHelmet title={'Home'} companyName={'ZealCurtains'} />

      <StyledRoot>
        <Container className="landgmainsection" maxWidth="lg" sx={{ pb: 7, pt: 2 }}>
          <Grid container spacing={2} className="imagecontainer" sx={{ position: 'relative', minHeight: '500px' }}>
            <Box xs={12} sm={6} sx={{ width: '40%' }} className="contntbox">
              <Box
                className="left_content_container"
                component="div"
                sx={{
                  backgroundColor: 'white',
                  top: '4.5rem',
                  position: 'absolute',
                  width: '600px',
                  left: 0,
                  p: 5,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ paddingBottom: '1rem' }}>
                  <Typography
                    gutterBottom
                    sx={{
                      fontSize: { xs: '26px', sm: '36px' },
                      fontWeight: '400',
                      color: colors?.grey,
                      lineHeight: { xs: '31px', sm: '43px' },
                    }}
                  >
                    Style Meets Function:
                    <br />
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="span"
                      className="boldfont"
                      sx={{
                        fontSize: { xs: '26px', sm: '36px' },
                        color: colors?.grey,
                        fontWeight: '900',
                        lineHeight: { xs: '31px', sm: '43px' },
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        component="span"
                        className="boldfont"
                        sx={{
                          color: colors.primary,
                          fontSize: { xs: '26px', sm: '36px' },
                          fontWeight: '900',
                          paddingRight: '1rem',
                        }}
                      >
                        Elevate Your Space
                      </Typography>
                      with Exquisite Curtains
                    </Typography>
                  </Typography>

                  <Typography
                    gutterBottom
                    sx={{ fontSize: { xs: '14px', sm: '20px' }, color: 'black', fontWeight: '400', lineHeight: '24px' }}
                  >
                    Our Experts Visit Homes Across Dubai to Provide Tailor-Made Window Covering Solutions.
                  </Typography>
                </Box>
                <Box>
                  <Stack
                    direction={{ xs: 'row', sm: 'row', lg: 'row' }}
                    alignItems={'center'}
                    sx={{ justifyContent: 'start' }}
                    spacing={2}
                  >
                    <Button
                      component={Link}
                      to="/booking"
                      variant="contained"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        boxShadow: 'none',
                        width: '100%',
                      }}
                      className="zeal-btn-solid lngbtnmain"
                    >
                      <img src={`/assets/images/Header/icon.png`} alt="icon" className="lngpgicon" />
                      Book A Free Home Visit
                    </Button>
                    <Button
                      component={Link}
                      to="/estimate"
                      variant="contained"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        boxShadow: 'none',
                        width: '100%',
                        fontWeight: '700',
                      }}
                      className="zeal-btn-outline lngbtn hidebtn"
                    >
                      Calculate Estimate
                    </Button>
                  </Stack>
                  <Typography
                    gutterBottom
                    sx={{ fontSize: { xs: '10px', sm: '14px' }, color: 'black', paddingTop: '4px' }}
                  >
                    Select your convenient time slot in the next step.
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box xs={12} sm={6} sx={{ width: '60%' }} className="imagebox">
              <Box component="div" sx={{ height: '100%' }}>
                <img
                  src={`/assets/images/Home/mainimg.jpg`}
                  alt="mainimg"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Box>
            </Box>
          </Grid>
        </Container>
      </StyledRoot>

      <Banner />
      {curtainTypesData?.map((curtain, key) => (
        <Fragment key={key}>
          {curtain?.theme === 'left-align' ? (
            <StyledRoot>
              <Container maxWidth="lg" className="gridsun" sx={{ paddingTop: '45px', paddingBottom: '5px' }}>
                <Stack
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  direction={{ lg: 'row', md: 'row', xs: 'column-reverse' }}
                >
                  <div className="left-column spleft" style={{ width: '50%', flex: 1, padding: '0 90px 0 0' }}>
                    <Typography
                      gutterBottom
                      className="rptop boldfont"
                      sx={{
                        color: colors.primary,
                        fontWeight: '800',
                        fontStyle: 'normal',
                        fontSize: { xs: '14px', sm: '17px' },
                      }}
                    >
                      {curtain?.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      className="boldfont"
                      gutterBottom
                      sx={{
                        color: colors?.grey,
                        fontWeight: '900',
                        fontSize: { xs: '29px', sm: '36px' },
                        lineHeight: '43.2px',
                      }}
                    >
                      {curtain?.highlight}
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontWeight: '400',
                        fontSize: { xs: '13.5px', sm: '15px' },
                        lineHeight: '18px',
                        marginTop: '10px',
                        marginBottom: '20px',
                      }}
                    >
                      {curtain.description}
                    </Typography>

                    <Button
                      component={Link}
                      to="/estimate"
                      state={curtain.category}
                      variant="contained"
                      className="zeal-btn-outline mbbtn btnsize  marBotm"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        border: '1px solid  rgb(239, 73, 35)',
                        boxShadow: 'none',
                        color: 'rgb(239, 73, 35)',
                        fontWeight: '700',
                        width: '200px',
                      }}
                    >
                      Calculate Estimate
                    </Button>
                  </div>
                  <div className="right-column" style={{ width: { xs: '100%', sm: '50%' } }}>
                    <img
                      src={curtain.bannerImage}
                      alt={curtain.alt}
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      loading="lazy"
                    />
                  </div>
                </Stack>
              </Container>
            </StyledRoot>
          ) : (
            <StyledRoot>
              <Container maxWidth="lg" className="gridsun" sx={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Stack alignItems={'center'} justifyContent={'space-between'} direction={{ lg: 'row', md: 'row' }}>
                  <div className="right-column" style={{ width: { xs: '100%', sm: '50%' } }}>
                    <img
                      src={curtain.bannerImage}
                      alt={curtain.alt}
                      style={{ objectFit: 'cover', width: '100%' }}
                      loading="lazy"
                    />
                  </div>
                  <div className="left-column spright" style={{ width: '100%', flex: 1, padding: '0 50px' }}>
                    <Typography
                      gutterBottom
                      className="rptop boldfont"
                      sx={{ color: colors.primary, fontWeight: '800', fontSize: { xs: '14px', sm: '17px' } }}
                    >
                      {curtain?.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className="boldfont"
                      sx={{
                        color: colors?.grey,
                        fontWeight: '900',
                        fontSize: { xs: '29px', sm: '36px' },
                        lineHeight: '43.2px',
                      }}
                    >
                      {curtain?.highlight}
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontWeight: '400',
                        fontSize: { xs: '12px', sm: '15px' },
                        lineHeight: '18px',
                        marginTop: '10px',
                        marginBottom: '20px',
                      }}
                    >
                      {curtain?.description}
                    </Typography>

                    <Button
                      component={Link}
                      to="/estimate"
                      state={curtain?.category}
                      variant="contained"
                      className="zeal-btn-outline mbbtn btnsize"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        border: '1px solid  rgb(239, 73, 35)',
                        boxShadow: 'none',
                        color: 'rgb(239, 73, 35)',
                        fontWeight: '700',
                        width: '200px',
                      }}
                    >
                      Calculate Estimate
                    </Button>
                  </div>
                </Stack>
              </Container>
            </StyledRoot>
          )}
        </Fragment>
      ))}

      {/* all product */}
      <StyledRoot>
        <Container
          maxWidth="lg"
          sx={{ paddingTop: { xs: '20px', sm: '75px' }, paddingBottom: { xs: '20px', sm: '55px' } }}
        >
          <Grid>
            <Typography
              className=""
              component="div"
              gutterBottom
              sx={{ fontWeight: '800', fontSize: '17px', textAlign: 'center', color: colors?.grey, mb: 4 }}
            >
              All products available in
            </Typography>
          </Grid>
          <Grid container spacing={2} direction="row">
            <Grid item xs={12} sm={6}>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  backgroundColor: colors.secondary,
                  padding: '5px',
                  borderRadius: '30px',
                  justifyContent: 'start',
                }}
              >
                <Box
                  sx={{
                    width: '85px',
                    height: '85px',
                  }}
                >
                  <img
                    src={`/assets/images/Home/punch.png`}
                    className="productimg"
                    alt="image1"
                    style={{ objectFit: 'cover', width: '100%', height: '100%', margin: '0 10px' }}
                  />
                </Box>
                <Typography
                  className=""
                  component="div"
                  gutterBottom
                  sx={{ fontWeight: '800', fontSize: { xs: '14px', sm: '22px' }, color: colors.primary, ml: 8 }}
                >
                  MANUAL OPTION
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  flexDirection: 'row',
                  backgroundColor: colors.secondary,
                  padding: '5px',
                  borderRadius: '30px',
                }}
              >
                <Box
                  sx={{
                    width: '85px',
                    height: '85px',
                  }}
                >
                  <img
                    src={`/assets/images/Home/settings.png`}
                    className="productimg"
                    alt="image2"
                    style={{ objectFit: 'cover', width: '100%', height: '100%', margin: '0 10px' }}
                  />
                </Box>
                <Typography
                  className=""
                  component="div"
                  gutterBottom
                  sx={{ fontWeight: '800', fontSize: { xs: '14px', sm: '22px' }, color: colors.primary, ml: 8 }}
                >
                  MOTORIZED OPTION
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </StyledRoot>
      <Zealbanner />
      <StyledRoot>
        <Container maxWidth="lg" sx={{ paddingTop: '45px', paddingBottom: '45px' }}>
          <Typography
            className="boldfont"
            align="center"
            style={{ fontSize: '24px', marginBottom: '20px', color: colors?.primary, paddingBottom: '1rem' }}
          >
            Brand Partners & Loyal Clients
          </Typography>

          <Grid container spacing={2} alignItems={'center'} justifyContent="space-around">
            <Grid item xs={4} sm={4} md={2}>
              <img src={`/assets/images/brand/civilco.jpg`} alt="civilco" />
            </Grid>
            <Grid item xs={4} sm={4} md={2}>
              <img src={`/assets/images/brand/acc.jpg`} alt="acc" />
            </Grid>

            <Grid item xs={4} sm={4} md={2}>
              <img src={`/assets/images/brand/alnabbodah.jpg`} alt="alnabbodah" />
            </Grid>
            <Grid item xs={4} sm={4} md={2}>
              <img src={`/assets/images/brand/Atlantis_Dubai_Logo.jpg`} alt="alhabtoor" />
            </Grid>
            <Grid item xs={4} sm={4} md={2}>
              <img
                src={`/assets/images/brand/emaar-logo.png`}
                alt="foxhub"
                style={{ marginTop: isMobile ? '2rem' : '' }}
              />
            </Grid>
          </Grid>
        </Container>
      </StyledRoot>
      <Feature />
    </>
  );
}
