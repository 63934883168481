import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography, Button, Box, Stack, TextField, Grid, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
// import Modal from '@mui/material/Modal';
import { colors } from '../theme/colors';
import { useDevice } from '../hooks/useResponsive';
import { getAllSlot, getSlotDetail } from '../server/api/slot';
import EmojiSelector from '../components/EmojiSelector';
import { createFeedback } from '../server/api/feedback';

export default function FeedbackPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { loading, SloteView } = useSelector((state) => ({
    loading: state.slot.loading,
    SloteView: state.slot.slotDetails.response,
  }));
  // const style = {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   width: 400,
  //   bgcolor: 'background.paper',
  //   boxShadow: 24,
  //   p: 4,
  // };
  const category = localStorage?.getItem('title');
  const Height = localStorage?.getItem('height');
  const Width = localStorage?.getItem('width');
  const dimension = Height && Width ? `${Height} x ${Width} ` : null;

  const [emojiWorkQuality, setEmojiWorkQuality] = useState(0);
  const [emojiExperience, setEmojiExperience] = useState(0);
  const [emojiCustomerRelation, setEmojiCustomerRelation] = useState(0);

  // const [feedbackModal, setFeedbackModal] = useState(false);

  const averageRatingValue = (
    (Number(emojiWorkQuality) + Number(emojiExperience) + Number(emojiCustomerRelation)) /
    3
  ).toFixed(2);
  const onSubmit = async (value, e) => {
    e?.preventDefault();
    const credentials = {
      state: {
        ...value,
        appoinment: SloteView?._id,
        name: SloteView?.name,
        phone: SloteView?.phone,
        workQualityRating: emojiWorkQuality,
        experienceRating: emojiExperience,
        customerRelationRating: emojiCustomerRelation,
        averageRating: parseFloat(averageRatingValue),
      },
      dispatch,
      navigate,
    };
    dispatch(createFeedback(credentials));
    localStorage.removeItem('title');
    localStorage.removeItem('height');
    localStorage.removeItem('width');
    localStorage.removeItem('category');

    // setFeedbackModal(true);
  };

  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      phone: '',
    },
  });

  const CustomButton = styled(Button)({
    background: colors.primary,
    color: 'white',
    borderRadius: '0',
    boxShadow: 'none',
    fontSize: '18px',
    fontWeight: '900',
    '&:hover': {
      background: colors.primary,
      color: 'white',
    },
  });

  const { isMobile } = useDevice();

  useEffect(() => {
    dispatch(getAllSlot({ dispatch }));
  }, [dispatch]);

  useEffect(() => {
    const credentials = {
      id: params?.id,
      dispatch,
    };
    dispatch(getSlotDetail(credentials));
  }, [dispatch, params?.id]);

  return (
    <>
      {/* {feedbackModal && (
        <Modal
          open={feedbackModal}
          onClose={() => setFeedbackModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                mt: 2,
                paddingBottom: '1rem',
                paddingTop: '1rem',
                color: colors?.grey,
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <img
                src={`/assets/images/Header/tick.png`}
                alt="success"
                style={{ width: '70px', height: '70px', objectFit: 'cover', textAlign: 'center' }}
              />
            </div>
            <Typography
              id="modal-modal-title"
              className="boldfont"
              variant="h6"
              component="h2"
              sx={{ pb: 2, color: colors?.grey, textAlign: 'center' }}
            >
              Successfully Submitted
            </Typography>
            <Box className="btndiv">
              <Button
                component={Link}
                className="popupbtn "
                to="/home"
                sx={{ alignItems: 'center', display: 'flex', color: 'white' }}
              >
                {' '}
                Explore more
              </Button>
            </Box>
          </Box>
        </Modal>
      )} */}
      <Container maxWidth="md">
        <Stack direction={isMobile ? 'column' : 'row'} spacing={isMobile ? '50px' : '100px'} display={'flex'}>
          <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ color: colors?.grey, fontSize: '28px', fontWeight: 800 }}>{SloteView?.name}</Typography>
              <Typography sx={{ color: colors?.grey, fontSize: '22px', fontWeight: 800 }}>
                {SloteView?.phone}
              </Typography>
              <Typography sx={{ color: colors?.grey, fontSize: '18px', fontWeight: 400 }}>
                {SloteView?.email}
              </Typography>
              <Typography
                className="boldfont"
                sx={{ display: isMobile ? 'box' : 'none', color: colors?.primary, fontSize: '33px', marginTop: '1rem' }}
              >
                We Value <br /> Your Feedback
              </Typography>
            </Box>

            <Box>
              <div className="maindivrow">
                <div>
                  <Typography pt={2} sx={{ color: colors?.primary, fontSize: '18px', fontWeight: 400 }}>
                    Appointment Date
                  </Typography>
                  <Typography sx={{ color: colors?.grey, fontSize: '14px', fontWeight: 600 }}>
                    {moment(SloteView?.date).format('Do MMMM YYYY')}
                  </Typography>
                </div>
                {category && (
                  <div className="jobdetails">
                    <Typography pt={2} sx={{ color: colors?.primary, fontSize: '18px', fontWeight: 400 }}>
                      Appointment Details
                    </Typography>
                    <Typography sx={{ color: colors?.grey, fontSize: '14px', fontWeight: 600 }}>
                      {`${category} ${dimension ? `( ${dimension})` : ''}`}
                    </Typography>
                  </div>
                )}
              </div>
            </Box>
          </Box>

          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Box>
              <Typography
                className="boldfont"
                sx={{ display: isMobile ? 'none' : 'box', color: colors?.primary, fontSize: '33px', marginTop: '1rem' }}
              >
                We Value Your Feedback
              </Typography>

              <Typography
                className="boldfont"
                pt={isMobile ? 0 : 3}
                sx={{ color: isMobile ? colors?.primary : colors?.grey, fontSize: '22px', fontWeight: 800 }}
              >
                Work Quality
              </Typography>
              <Typography pt={1} sx={{ color: colors?.grey, fontSize: '15px', fontWeight: 400 }}>
                Quality of fabric, materials, installation.
              </Typography>
              <EmojiSelector select={emojiWorkQuality} setSelect={setEmojiWorkQuality} />

              <Typography pt={3} sx={{ color: colors?.grey, fontSize: '22px', fontWeight: 800 }}>
                Experience
              </Typography>
              <Typography pt={1} sx={{ color: colors?.grey, fontSize: '15px', fontWeight: 400 }}>
                The degree of vibe elevated your home..
              </Typography>
              <EmojiSelector select={emojiExperience} setSelect={setEmojiExperience} />

              <Typography pt={3} sx={{ color: colors?.grey, fontSize: '22px', fontWeight: 800 }}>
                Customer Relation
              </Typography>
              <Typography pt={1} sx={{ color: colors?.grey, fontSize: '15px', fontWeight: 400 }}>
                Professionalism, Behaviour etc..
              </Typography>
              <EmojiSelector select={emojiCustomerRelation} setSelect={setEmojiCustomerRelation} />

              <Typography pt={3} sx={{ color: colors?.grey, fontSize: '22px', fontWeight: 800 }}>
                Any Suggestions?
              </Typography>
              <Grid mt={'20px'}>
                <TextField
                  id="address"
                  placeholder="Type if only to suggest on our service..
                Your suggestions will helps us to make better service. Thank You!"
                  fullWidth
                  multiline
                  type="text"
                  className="custom-textfield-feedback"
                  rows={4}
                  name="message"
                  {...register('message', {
                    required: { value: false },
                  })}
                />
              </Grid>
              <Grid item xs={12} mt={isMobile ? 4 : 3} mb={'60px'}>
                <CustomButton type="submit" variant="contained" className="request-btn" fullWidth>
                  SUBMIT REVIEW
                  {loading && <CircularProgress color="inherit" sx={{ color: 'white', mx: 2 }} size={'18px'} />}
                </CustomButton>
              </Grid>
            </Box>
          </form>
        </Stack>
      </Container>
    </>
  );
}
