import React from 'react';
import PropTypes from 'prop-types';

const VeryPoorIcon = ({ color }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 60.6 60.6"
    style={{ enableBackground: 'new 0 0 60.6 60.6', height: '50px' }}
    xmlSpace="preserve"
  >
    <style type="text/css">
      {`
        .st0{fill:#F5F5F5;}
        .st1{fill:#E0E0E0;}
        .st2{fill:#EBEBEB;}
        .st3{display:none;}
        .st4{display:inline;}
        .st5{fill:#263238;}
        .st6{fill:#FFFFFF;}
        .st7{fill:#455A64;}
        .st8{opacity:0.4;}
        .st9{fill:#FF725E;}
        .st10{opacity:0.3;}
        .st11{fill:#FFBF9D;}
        .st12{fill:#FF9A6C;}
        .st13{opacity:0.6;}
      `}
    </style>
    <g id="Background_Complete" />
    <g id="Background_Simple" className="st3" />
    <g id="Floor" />
    <g id="Device" />
    <g id="Plant" />
    <g id="Shadow_2" />
    <g id="Shadow_1" />
    <g id="Emotions">
      <g>
        <g>
          <ellipse
            transform="matrix(0.9855 -0.1698 0.1698 0.9855 -4.7027 5.5822)"
            className="st1"
            style={{
              fill: color,
              opacity: '0.6',
            }}
            cx="30.3"
            cy="30.3"
            rx="30.3"
            ry="30.3"
          />
        </g>
        <g>
          <path
            className="st5"
            d="M39.1,25.1c0,2.2,1.4,4,3,4c1.7,0,3-1.8,3-4s-1.4-4-3-4C40.5,21.1,39.1,22.9,39.1,25.1z"
          />
        </g>
        <g>
          <ellipse className="st5" cx="18.2" cy="25.3" rx="3" ry="4" />
        </g>
        <g>
          <path
            className="st5"
            d="M21.7,34.4c1.1-3.8,4.8-6.7,8.8-6.6c4,0.2,7.6,3.2,8.3,7.1c0.2,1.2-1.6,1.7-1.8,0.5
            c-0.6-3.1-3.4-5.6-6.5-5.8c-3.2-0.1-6.2,2.1-7,5.2C23.2,36,21.4,35.6,21.7,34.4L21.7,34.4z"
          />
        </g>
      </g>
    </g>
    <g id="Speech_Bubble_2" />
    <g id="Speech_Bubbles_1" />
    <g id="Character_00000028301238202153125090000011725317768445582000_" />
  </svg>
);

export default VeryPoorIcon;

VeryPoorIcon.propTypes = {
  color: PropTypes.string,
};
