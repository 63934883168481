const curtainTypesData = [
  {
    id: 1,
    logoImage: '/assets/images/Estimate/interior.png',
    bannerImage: '/assets/images/Home/interior.png',
    alt: 'sun white sheer curtain',
    content: 'SUN WHITE SHEER\nCURTAIN',
    price: 150,
    category: 'sheer',
    title: 'SUN WHITE SHEER CURTAIN',
    theme: 'left-align',
    highlight: 'I need Privacy with a Subtle Glow!',
    description:
      'Enjoy privacy without sacrificing natural light. Our White Sheer Curtains strike the perfect balance, allowing sunlight to filter through while providing a gentle, soft glow that adds warmth and elegance to your rooms.',
  },
  {
    id: 2,
    logoImage: '/assets/images/Estimate/sofa.jpg',
    bannerImage: '/assets/images/Home/nightblackout.jpg',
    alt: 'night blackout curtain',
    content: 'NIGHT BLACKOUT\nCURTAIN',
    price: 200,
    category: 'blackout',
    title: 'NIGHT BLACKOUT CURTAIN',
    theme: 'right-align',
    highlight: 'Immerse Yourself in Dream Darkness!',
    description:
      "Our Blackout Curtains are meticulously crafted to block out sunlight and external distractions, ensuring a restful and peaceful ambiance for a good night's sleep. Enjoy privacy, enhance your home experience.",
  },
  {
    id: 3,
    logoImage: '/assets/images/Estimate/bed.png',
    bannerImage: '/assets/images/Home/blackout.jpg',
    alt: 'blackout sheer combined',
    content: 'BLACKOUT SHEER\nCOMBINED',
    price: 320,
    category: 'sheerBlackout',
    title: 'BLACKOUT & SHEER COMBINED ',
    theme: 'left-align',
    highlight: 'Switch the Best of Both Worlds!',
    description:
      'Enjoy privacy without sacrificing natural light. Our White Sheer Curtains strike the perfect balance, allowing sunlight to filter through while providing a gentle, soft glow that adds warmth and elegance to your rooms.',
  },
  {
    id: 4,
    logoImage: '/assets/images/Estimate/chair.png',
    bannerImage: '/assets/images/Home/roller.jpg',
    alt: 'roller blind curtain',
    content: 'ROLLER BLIND\nCURTAIN',
    price: 120,
    category: 'blinds',
    title: 'ROLLER BLIND CURTAIN',
    theme: 'right-align',
    highlight: 'Embrace Minimalism Sleek & Stylish!',
    description:
      'Our Roller Blinds offer a sleek and contemporary solution for light control and privacy. Enjoy a clutter-free window with these space-saving blinds that effortlessly blend into any interior, adding a touch of modern sophistication.',
  },
];

export default curtainTypesData;
