import React from 'react';
import PropTypes from 'prop-types';

const PoorIcon = ({ color }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 60.6 60.6"
    style={{ enableBackground: 'new 0 0 60.6 60.6', height: '50px' }}
    xmlSpace="preserve"
  >
    <style type="text/css">
      {`
      .st0{fill:#F5F5F5;}
      .st1{fill:#E0E0E0;}
      .st2{fill:#EBEBEB;}
      .st3{display:none;}
      .st4{display:inline;}
      .st5{fill:#263238;}
      .st6{fill:#FFFFFF;}
      .st7{fill:#455A64;}
      .st8{opacity:0.4;}
      .st9{fill:#FF725E;}
      .st10{opacity:0.3;}
      .st11{fill:#FFBF9D;}
      .st12{fill:#FF9A6C;}
      .st13{opacity:0.6;}
      `}
    </style>
    <g id="Background_Complete" />
    <g id="Background_Simple" className="st3" />
    <g id="Floor" />
    <g id="Device" />
    <g id="Plant" />
    <g id="Shadow_2" />
    <g id="Shadow_1" />
    <g id="Emotions">
      <g>
        <g>
          <ellipse
            transform="matrix(0.4289 -0.9034 0.9034 0.4289 -10.0624 44.6551)"
            className="st1"
            style={{
              fill: color,
              opacity: '0.6',
            }}
            cx="30.3"
            cy="30.3"
            rx="30.3"
            ry="30.3"
          />
        </g>
        <g>
          <path
            className="st5"
            d="M40.3,25.1c0,2.2,1.4,4,3,4c1.7,0,3-1.8,3-4s-1.4-4-3-4C41.7,21.1,40.3,22.9,40.3,25.1z"
          />
        </g>
        <g>
          <path
            className="st5"
            d="M15.1,25.1c0,2.2,1.4,4,3,4c1.7,0,3-1.8,3-4s-1.4-4-3-4C16.5,21.1,15.1,22.9,15.1,25.1z"
          />
        </g>
      </g>
      <g>
        <path
          className="st5"
          d="M37.8,34.2c-0.3,0-0.5-0.1-0.7-0.3c-1.8-1.8-4.9-2.5-8.3-1.9c-1.6,0.3-3.2,0.8-4.7,1.6
          c-0.5,0.3-1.1,0.1-1.3-0.4c-0.3-0.5-0.1-1.1,0.4-1.3c1.6-0.8,3.4-1.4,5.2-1.8c4.1-0.7,7.7,0.1,10,2.4c0.4,0.4,0.4,1,0,1.4
          C38.3,34.1,38,34.2,37.8,34.2z"
        />
      </g>
    </g>
    <g id="Speech_Bubble_2" />
    <g id="Speech_Bubbles_1" />
    <g id="Character_00000028301238202153125090000011725317768445582000_" />
  </svg>
);

export default PoorIcon;

PoorIcon.propTypes = {
  color: PropTypes.string,
};
