import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { colors } from '../../../theme/colors';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const Zealbanner = () => (
  <div>
    <StyledRoot>
      <Container maxWidth="lg" sx={{ paddingTop: '45px', paddingBottom: '45px' }}>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={6}>
            <div style={{ position: 'relative' }}>
              <img
                className="quickimg"
                src={`/assets/images/Feature/quick.jpg`}
                alt="quick"
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '580px',
                  maxHeight: '550px',
                  objectFit: 'cover',
                }}
              />
              <div
                className="transparentdiv"
                style={{
                  position: 'absolute',
                  width: '170px',
                  height: '170px',
                  borderRadius: '50%',
                  right: '15%',
                  top: '28%',
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  className="zcareimg"
                  src={`/assets/images/Feature/z.png`}
                  alt="zcare"
                  style={{ width: '78px', height: '90px', objectFit: 'cover' }}
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              backgroundColor: colors.secondary,
              paddingTop: 'auto',
              paddingBottom: 'auto',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <div className="contentsection" style={{ padding: '0rem 4rem' }}>
              <Typography
                gutterBottom
                className="headingh1 boldfont"
                sx={{
                  color: colors.primary,
                  fontWeight: '900',
                  fontSize: { xs: '23px', sm: '36px' },
                  lineHeight: '43px',
                }}
              >
                Quick Installation, <br />
                ŹCare Assurance
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontWeight: '400',
                  fontSize: { xs: '12px', sm: '15px' },
                  lineHeight: '18px',
                  marginTop: '10px',
                  marginBottom: '20px',
                }}
              >
                Effortless Delivery and Installation of Your Window Curtains in Dubai, within 5 Days. Enjoy Peace of
                Mind with ŹCare, Offering a 1-Year Warranty on Curtains and Roller Blinds, and an Impressive 5-Year
                Warranty on Curtain Motors.
              </Typography>
              <Button
                component={Link}
                to="/booking"
                variant="contained"
                className="zeal-btn-solid bookbutton"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'rgb(239, 73, 35)',
                  boxShadow: 'none',
                  width: '230px',
                }}
              >
                <img src={`/assets/images/Feature/icon.png`} alt="icon" style={{ marginRight: '8px' }} />
                Book A Free Home Visit
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </StyledRoot>
    <StyledRoot>
      <Container maxWidth="lg" sx={{ paddingTop: '5px', paddingBottom: '45px' }}>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={6}>
            <div>
              <img
                className="quickimg"
                src={`/assets/images/Feature/artistry.png`}
                alt="quick"
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '580px',
                  maxHeight: '550px',
                  objectFit: 'cover',
                }}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              backgroundColor: colors.secondary,
              paddingTop: 'auto',
              paddingBottom: 'auto',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <div className="contentsection" style={{ padding: '0rem 4rem' }}>
              <Typography
                gutterBottom
                className="headingh1 boldfont"
                sx={{
                  color: colors.primary,
                  fontWeight: '900',
                  fontSize: { xs: '23px', sm: '36px' },
                  lineHeight: '43px',
                }}
              >
                The Artistry of our ŹExperts!
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontWeight: '400',
                  fontSize: { xs: '12px', sm: '15px' },
                  lineHeight: '18px',
                  marginTop: '10px',
                  marginBottom: '20px',
                }}
              >
                Experience the mastery of ŹExperts as they curate stunning curtains tailored to your style. From Expert
                Advice to Flawless Installation, Trust our Passionate Team to Transform Your Home into a Sanctuary of
                Beauty and Comfort.
              </Typography>
              <Button
                component={Link}
                to="/booking"
                variant="contained"
                className="zeal-btn-solid bookbutton"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'rgb(239, 73, 35)',
                  boxShadow: 'none',
                  width: '230px',
                }}
              >
                <img src={`/assets/images/Feature/icon.png`} alt="icon" style={{ marginRight: '8px' }} />
                Book A Free Home Visit
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </StyledRoot>
  </div>
);

export default Zealbanner;
