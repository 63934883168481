import { Navigate, useRoutes } from 'react-router-dom';
import ProductPage from './pages/Products';

// layouts
import SimpleLayout from './layouts/simple';
//
import Page404 from './pages/Page404';
import HomePage from './pages/Home';
import EstimatePage from './pages/Estimate';
import AboutPage from './pages/About';
import BookingPage from './pages/booking';
import FeedbackPage from './pages/feedback';

// ----------------------------------------------------------------------

export default function Router() {
  const routesList = [
    // public
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        {
          path: '/',
          children: [
            {
              path: 'home',
              element: <HomePage />,
            },
            {
              path: 'products',
              element: <ProductPage />,
            },
            {
              path: 'estimate',
              element: <EstimatePage />,
            },
            {
              path: 'about',
              element: <AboutPage />,
            },
            {
              path: 'booking',
              element: <BookingPage />,
            },
            {
              path: 'feedback/:id',
              element: <FeedbackPage />,
            },
            // add other routes here
          ],
        },

        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },

    // handle notfound routes
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const routes = useRoutes(routesList);

  return routes;
}
