import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link, useLocation } from 'react-router-dom';

import MenuItem from '@mui/material/MenuItem';
import Logo from '../../components/logo/Logo';

const pages = [
  { item: 'Home', link: '/home' },
  { item: 'Products', link: '/products' },
  { item: 'Estimate', link: '/estimate' },
  { item: 'About', link: '/about' },
];

function Header() {
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: 'white',
        boxShadow: 'none',
        p: { xs: 0, sm: 5 },
        px: { xs: 0, sm: 10 },
        pb: { xs: 2 },
        pt: { xs: 2 },
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Logo />
          {/* mobile view */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages?.map((page, key) => (
                <MenuItem key={key} onClick={handleCloseNavMenu}>
                  <Link to={page?.link} style={{ textDecoration: 'none', textAlign: 'center' }}>
                    <Typography textAlign="center" sx={{ color: 'black' }}>
                      {page?.item}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* web view */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'end' }}>
            {pages?.map((page, key) => (
              <Button
                key={key}
                component={Link}
                to={page?.link}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'black', display: 'block', mx: 3 }}
              >
                {page?.item}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {location?.pathname !== '/booking' && (
              <Box sx={{ alignSelf: 'center' }}>
                <Button
                  component={Link}
                  to="/booking"
                  variant="contained"
                  sx={{ display: 'flex', alignItems: 'center', boxShadow: 'none' }}
                  className="zeal-btn-solid hidebtn1"
                >
                  <img
                    src="/assets/images/Header/icon.png"
                    style={{ marginRight: '8px' }}
                    alt="icon"
                    className="btnimg"
                  />
                  Book A Free Home Visit
                </Button>
              </Box>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
