import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Box, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '../../theme/colors';
import { useDevice } from '../../hooks/useResponsive';

function EstimateCard({ price, containerSx }) {
  const { isMobile } = useDevice();

  const [curtainPrice, setCurtainPrice] = useState(price);

  const { register, watch } = useForm({
    defaultValues: {
      height: 100,
      width: 100,
    },
  });
  const height = watch('height');
  const width = watch('width');
  // console.log(height,width);

  localStorage.setItem('height', height);
  localStorage.setItem('width', width);

  const convertCmToMeter = (value) => value / 100;

  useEffect(() => {
    const calculatePrice = () => {
      const widthM = convertCmToMeter(width);
      const heightM = convertCmToMeter(height);

      const newPrice = widthM * heightM * price;
      setCurtainPrice(newPrice);
    };

    calculatePrice();
  }, [width, height, price]);

  return (
    <Box sx={containerSx}>
      <Typography
        variant="inherit"
        component={'p'}
        gutterBottom
        sx={{ color: colors.primary, mb: 2, mt: 3, fontSize: isMobile ? 14 : 20 }}
      >
        Enter measurements below↓
      </Typography>
      <form>
        <Stack direction={'row'}>
          <Box sx={{ textAlign: 'start' }}>
            <Typography mb={0.8} variant="body2" color={'GrayText'}>
              Height (cm)
            </Typography>
            <TextField
              name="height"
              className="product-measurement-input"
              sx={{
                width: '100%',
                mb: 2,
                bgcolor: colors.secondary,
                border: `1px solid ${colors.primary}`,
              }}
              InputLabelProps={{ shrink: true }}
              size="small"
              id="height"
              type="number"
              variant="outlined"
              {...register('height', {
                required: { value: true, message: 'Height is required' },
              })}
              inputProps={{ min: 1 }}
            />
          </Box>
          <Box sx={{ mx: 2.5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CloseIcon fontSize="small" color="disabled" />
          </Box>
          <Box sx={{ textAlign: 'start' }}>
            <Typography variant="body2" mb={0.8} color={'GrayText'}>
              Width (cm)
            </Typography>
            <TextField
              id="width"
              name="width"
              {...register('width', {
                required: { value: true, message: 'Width is required' },
                min: 0,
              })}
              className="product-measurement-input"
              sx={{
                width: '100%',
                mb: 2,
                bgcolor: colors.secondary,
                border: `1px solid ${colors.primary}`,
              }}
              InputLabelProps={{ shrink: true }}
              size="small"
              type="number"
              variant="outlined"
              inputProps={{ min: 1 }}
            />
          </Box>
        </Stack>
        <Stack width={'100%'} direction={'row'} alignItems={'center'} my={3} justifyContent={'space-between'}>
          <Typography fontSize={isMobile ? '14px' : '22px'} color={colors.primary}>
            Estimate*&nbsp;&nbsp;:
          </Typography>
          <Typography fontWeight={800} color={colors.black} fontSize={isMobile ? '30px' : '38px'}>
            AED {curtainPrice.toFixed(2)}
          </Typography>
        </Stack>
      </form>
    </Box>
  );
}

export default EstimateCard;

EstimateCard.propTypes = {
  price: PropTypes.number.isRequired,
  containerSx: PropTypes.object,
};
