import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Container, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { useDevice } from '../../../hooks/useResponsive';
import { colors } from '../../../theme/colors';
import { createEnquiry } from '../../../server/api/enquiry';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const CustomButton = styled(Button)({
  background: colors.primary,
  color: colors.secondary,
  borderRadius: '0',
  boxShadow: 'none',
  fontSize: '23px',
  fontWeight: '900',
  '&:hover': {
    background: '#be302f',
    color: 'white',
  },
});
Feature.propTypes = {
  hideSections: PropTypes.any,
};
export default function Feature() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useDevice();

  const resetForm = () => {
    reset();
  };

  const onSubmit = async (value, e) => {
    e?.preventDefault();
    const credentials = {
      state: value,
      dispatch,
      resetForm,
      navigate,
    };

    dispatch(createEnquiry(credentials));
  };

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      message: '',
    },
  });

  return (
    <div>
      <StyledRoot sx={{ marginBottom: isMobile ? '2rem' : 0 }}>
        <Container
          className=""
          maxWidth="lg"
          sx={{
            paddingTop: { xs: '0', sm: '45px' },
            paddingBottom: { xs: '0', sm: '125px' },
            paddingLeft: { xs: '0', sm: '16px' },
            paddingRight: { xs: '0', sm: '16px' },
          }}
        >
          <Grid container direction="row" sx={{ backgroundColor: colors.secondary }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{ padding: { xs: '1.5rem', sm: '5rem' }, marginTop: 'auto', marginBottom: 'auto' }}
            >
              <Typography
                className="boldfont"
                gutterBottom
                sx={{
                  color: colors.primary,
                  fontWeight: '900',
                  fontSize: { xs: '30px', sm: '48px' },
                  lineHeight: 'normal',
                  fontStyle: 'normal',
                }}
              >
                Anything to discuss before Schedule?
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontWeight: '400',
                  color: 'black',
                  fontSize: { xs: '15px', sm: '17px' },
                  lineHeight: '25px',
                  marginTop: '10px',
                  marginBottom: '20px',
                }}
              >
                One of our experts will get back to you within the day to discuss your home design and schedule our FREE
                Home visit.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sm={12}
              lg={6}
              sx={{
                backgroundColor: colors.secondary,
                alignItems: 'center',
                display: 'flex',
              }}
              pt={isMobile ? 2 : 5}
              pb={isMobile ? 8 : 5}
              px={isMobile ? 3 : 8}
            >
              <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel className="custom-inputlabel">Name</InputLabel>
                    <TextField
                      id="firstName"
                      placeholder="Your Name"
                      required
                      fullWidth
                      className="custom-textfield"
                      type="text"
                      name="name"
                      {...register('name', {
                        required: { value: true, message: 'Enquiry name is required' },
                      })}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel className="custom-inputlabel">Phone Number</InputLabel>
                    <TextField
                      id="firstName"
                      placeholder="Enter Phone Number"
                      required
                      fullWidth
                      className="custom-textfield"
                      type="number"
                      name="phone"
                      {...register('phone', {
                        required: { value: true, message: 'Enquiry phone is required' },
                        maxLength: 10,
                        minLength: 10,
                      })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel className="custom-inputlabel">Email</InputLabel>
                    <TextField
                      id="Email"
                      placeholder="Your Email"
                      required
                      fullWidth
                      variant="outlined"
                      type="email"
                      className="custom-textfield"
                      name="email"
                      {...register('email', {
                        required: { value: true, message: 'Enquiry email is required' },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel className="custom-inputlabel">Message</InputLabel>
                    <TextField
                      id="firstName"
                      placeholder="Ask Anything.."
                      fullWidth
                      multiline
                      type="text"
                      rows={4}
                      name="message"
                      {...register('message', {
                        required: { value: true, message: 'Enquiry Message is required' },
                      })}
                      className="custom-textfield"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomButton type="submit" variant="contained" className="request-btn boldfont" fullWidth>
                      Request a Callback!
                    </CustomButton>
                    <Typography
                      sx={{
                        color: colors?.grey,
                        fontSize: { xs: '11px', sm: '12px' },
                        textAlign: 'center',
                        paddingTop: '.4rem',
                      }}
                    >
                      ŹExperts will contact you with in 24hrs
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </StyledRoot>
    </div>
  );
}
