import React from 'react';
import PropTypes from 'prop-types';

const GoodIcon = ({ color }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 63.3 63.3"
    style={{ enableBackground: 'new 0 0 63.3 63.3', height: '50px' }}
    xmlSpace="preserve"
  >
    <style type="text/css">
      {`
    .st0{fill:#F5F5F5;}
    .st1{fill:#E0E0E0;}
    .st2{fill:#EBEBEB;}
    .st3{display:none;}
    .st4{display:inline;}
    .st5{fill:#263238;}
    .st6{fill:#FFFFFF;}
    .st7{fill:#455A64;}
    .st8{opacity:0.4;}
    .st9{fill:#FF725E;}
    .st10{opacity:0.3;}
    .st11{fill:#FFBF9D;}
    .st12{fill:#FF9A6C;}
    .st13{opacity:0.6;}
    `}
    </style>
    <g id="Background_Complete" />
    <g id="Background_Simple" className="st3" />
    <g id="Floor" />
    <g id="Device" />
    <g id="Plant" />
    <g id="Shadow_2" />
    <g id="Shadow_1" />
    <g id="Emotions">
      <g>
        <g>
          <g>
            <circle
              className="st1"
              style={{
                fill: color,
                opacity: '0.6',
              }}
              cx="31.7"
              cy="31.7"
              r="31.7"
            />
          </g>
          <g>
            <path
              className="st5"
              d="M42.1,26.3c0,2.3,1.4,4.2,3.2,4.2c1.7,0,3.2-1.9,3.2-4.2c0-2.3-1.4-4.2-3.2-4.2
            C43.6,22.1,42.1,24,42.1,26.3z"
            />
          </g>
          <g>
            <path
              className="st5"
              d="M15.8,26.3c0,2.3,1.4,4.2,3.2,4.2s3.2-1.9,3.2-4.2c0-2.3-1.4-4.2-3.2-4.2S15.8,24,15.8,26.3z"
            />
          </g>
        </g>
        <g>
          <path
            className="st5"
            d="M41.2,31.6c-0.9,4-4.7,7.2-8.9,7.2c-4.2,0-8-3.1-9-7.1c-0.3-1.2,1.6-1.8,1.8-0.6c0.8,3.2,3.7,5.8,7.1,5.7
          c3.3,0,6.3-2.5,7.1-5.7C39.6,30,41.5,30.4,41.2,31.6L41.2,31.6z"
          />
        </g>
      </g>
    </g>
    <g id="Speech_Bubble_2" />
    <g id="Speech_Bubbles_1" />
    <g id="Character_00000028301238202153125090000011725317768445582000_" />
  </svg>
);

export default GoodIcon;

GoodIcon.propTypes = {
  color: PropTypes.string,
};
